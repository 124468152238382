import React, { useEffect, useState } from 'react'

import * as Types from 'types'
import styled from 'styled-components'
import moment from 'moment'
import { useMutation, useApolloClient } from '@apollo/react-hooks'
import { UPDATE_CONTACT_SUBSCRIPTION} from 'graphql/_billing'
import { Pane, Heading, Text, toaster } from 'evergreen-ui'


import { Card, CardHeader, Button, Icon, TreatmentsTable, DiscountsTable, effectiveDateRangeInDateTime, CardUpcomingRenewal } from '@pearly/lib'
import { useModal } from 'components/modal-provider'
import { useAppGlobal } from 'components/app-global-provider'

export type Props = {
  className?: string
}

const IndexView = ({ className }: Props) => {
  const apolloClient = useApolloClient()
  const showContractSheet = useModal('contract')

  const [updateSubscription, updateSubscriptionStatus] = useMutation<
    Types.UpdateContactSubscription,
    Types.UpdateContactSubscriptionVariables
  >(UPDATE_CONTACT_SUBSCRIPTION)


  const { global } = useAppGlobal()
  if (!global) throw Error('Global App context not available')
  const contact = global.meContact

  // TODO: Need to load data from backend
  const currentSub = contact.subscriptions?.find(p => p)
  if (!(currentSub && currentSub?.plan)) throw Error('Contact data incomplete')

  const [subscription, setSubscription] = useState({
    cancelled: currentSub?.isCancelled,
    effectiveDate: currentSub?.startDate,
    endDate: currentSub?.endDate,
    status: currentSub?.status,
    planTerm: currentSub?.planTerm??contact.planTerm
  })

  
  useEffect(() => {
    if (updateSubscriptionStatus.error) toaster.danger('Unable to update membership')
    else if (updateSubscriptionStatus.data && !updateSubscriptionStatus.loading) {
      const sub = updateSubscriptionStatus.data.updateContactSubscription
      if (!sub) toaster.danger('Unable to update membership')
      else {
        const updatedData = { ...subscription }
        updatedData.cancelled = sub?.isCancelled
        updatedData.status = sub?.status
        updatedData.effectiveDate = sub?.startDate
        updatedData.endDate = sub?.endDate
        setSubscription(updatedData)
        toaster.success(`Plan membership has been accepted!`)
      }
    }
  }, [updateSubscriptionStatus])

  const acceptPlan = () => {
    updateSubscription({ variables: { id: currentSub!.id, accept: true } })
  }

  return (
    <Pane className={className}>
      <Card gridArea="plan" padding={0}>
        <CardHeader>
          <Pane display="flex">
            <Icon icon={['fad', 'files-medical']} size="lg" color="selected" paddingRight={12} />

            <Heading size={500}>{contact.secure.firstName}'s Membership Plan</Heading>
          </Pane>
          {/* <Button iconBefore={['fas', 'id-card']}>Show ID Card</Button> */}
        </CardHeader>
        <Pane padding={16} display="flex" flexDirection="column">
          <Pane display="grid" gridTemplateColumns="auto 1fr" gridRowGap={12} gridColumnGap={40} marginBottom={16}>
            <Text size={400} textTransform="uppercase" color="selected">
              Member:
            </Text>
            <Text size={500}>
              {contact.secure.firstName} {contact.secure.lastName}
            </Text>
            <Text size={400} textTransform="uppercase" color="selected">
              ID:
            </Text>
            <Text size={500}>{contact.code.toUpperCase()}</Text>
            <Text size={400} textTransform="uppercase" color="selected">
              Plan:
            </Text>
            <Text size={500}>{currentSub.plan.name}</Text>
            <Text size={400} textTransform="uppercase" color="selected">
              Effective Dates:
            </Text>
            <Text size={500}>{effectiveDateRangeInDateTime(subscription.effectiveDate, subscription.endDate)}</Text>
            <Text size={400} textTransform="uppercase" color="selected">
              {subscription.cancelled ? 'Termination' : 'Renewal'} Date:
            </Text>
            <Text size={500}>{`${moment.utc(subscription.endDate).add(1, "days").format('M/D/YYYY')}`}</Text>
            <Text size={400} textTransform="uppercase" color="selected">
              Subscription:
            </Text>
            <Text size={500}>
              {contact.planTerm === Types.PlanTerm.ANNUAL
                ? `$${currentSub?.plan?.annualPrice} / year`
                : `$${currentSub?.plan?.monthlyPrice} / month`}
            </Text>
            <Text size={400} textTransform="uppercase" color="selected">
              Collection Method:
            </Text>
            <Text size={500}>
              {currentSub.collectingMethod === Types.collectingMethod.AUTO
                ? `Automatic`
                : `Manual`}
            </Text>
            <Text size={400} textTransform="uppercase" color="selected">
              Status:
            </Text>
            <Text size={500}>{subscription.status}</Text>

          </Pane>
          <Pane display="flex" flexDirection="row" gridColumnGap={40}>
            {(subscription.status === Types.PlanSubscriptionStatus.PROPOSED) ?
              (
                <Button
                  isLoading={updateSubscriptionStatus.loading}
                  intent="success"
                  onClick={() => acceptPlan()}
                >
                  Accept
                </Button>
              ) : (<></>)}
          </Pane><br />
          <Button
            appearance="minimal"
            alignSelf="center"
            onClick={() =>
              showContractSheet({
                apolloClient: apolloClient,
                planId: currentSub!.plan!.id,
                cancelled: subscription.cancelled,
                effectiveDate: subscription.effectiveDate,
                endDate: subscription.endDate,
                planTerm: subscription.planTerm!,
              })
            }
          >
            View Plan Contract
          </Button>
        </Pane>
        {console.log('currentSub--------------', currentSub)}
        {currentSub?.renewalSubscriptionId?
          
          <Card backgroundColor="white" elevation={0} padding={0} margin={16} >
              <CardHeader justifyContent="space-between">
                <Heading size={500}>Upcoming Renewal</Heading>
              </CardHeader>
              <CardUpcomingRenewal renewalSubscriptionId={currentSub?.renewalSubscriptionId} apolloClient={apolloClient} dependent={false} isAdmin={false}></CardUpcomingRenewal>
          </Card>:<></> 
        }
      </Card>
      <Card gridArea="procedures" padding={0}>
        <CardHeader>
          <Heading size={500}>Included Treatments</Heading>
        </CardHeader>
        <TreatmentsTable treatments={currentSub?.plan.treatments} showValue={currentSub?.plan.showValue} />
      </Card>
      <Card gridArea="discounts" padding={0}>
        <CardHeader>
          <Heading size={500}>Procedure Discounts by Category</Heading>
        </CardHeader>
        <DiscountsTable plan={currentSub?.plan} />
      </Card>
    </Pane>
  )
}

export default styled(IndexView)`
  display: grid;
  grid-template-rows: auto auto auto;
  grid-template-columns: 1fr;
  grid-template-areas:
    'plan'
    'procedures'
    'discounts';
  grid-gap: 16px;
`
