import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import { Route, Redirect, Switch } from 'react-router-dom'
import { AuthCheck } from 'reactfire'
import { withPrefix } from "gatsby"

import { TokenProvider, FullScreen, Spinner } from '@pearly/lib'
import { AppHeaderSmall } from 'components/app-header-mobile'

import { Pane } from 'evergreen-ui'
import FirebaseProvider from 'components/firebase-provider'
import ApolloProvider from 'components/apollo-provider'
import { useApolloClient } from '@apollo/react-hooks'
import { ModalProvider } from 'components/modal-provider'

import { useViewport } from 'utility/use-viewport'

import { AppGlobalProvider, useAppGlobal } from 'components/app-global-provider'

import AppHeader from 'components/app-header'
import AppSidebar from 'components/app-sidebar'
import LoginView from 'views/login'
import ResetPasswordView from 'views/resetPassword'

import IndexView from 'views'
import ProfileView from 'views/profile'
import RenewalView from 'views/renewal'
import BillingView from 'views/billing'
import SupportView from 'views/support'
import InviteView from 'views/invite'
import PracticeView from 'views/practice'
import DependentView from 'views/dependent'
import PastSubscriptions from 'views/past-subscriptions'


const clearentHPPScript = process.env.GATSBY_CLEARENT_HPP_SCRIPT

export type Props = {
  className?: string
}

const Dashboard = ({ className }: Props) => {
  const { width } = useViewport();
  const breakpoint = 680;

  return width > breakpoint ? <StyledDashboardBig className={className} /> : <StyledDashboardSmall className={className} />
}

const DashboardSmall = ({ className }: Props) => {
  const { loading, global } = useAppGlobal()

  return loading || !global ? (
    <FullScreen display="flex" justifyContent="center" alignItems="center">
      <Spinner />
    </FullScreen>
  ) : (
    <>
      <Helmet>
        <title>{global.meContact.account.programName}</title>
        <script async={false} type="text/javascript" id="clearent-hpp" src={`${clearentHPPScript}`}></script>
        <script async={false} src={withPrefix('clearent-init.js')} type="text/javascript" />
      </Helmet>

      <FullScreen className={className} height="100%">
        <AppHeaderSmall />
        <Pane gridArea="view" overflow="scroll">
          <ViewGrid maxWidth={1000} paddingLeft={0} paddingRight={16} marginBottom={48}>
            <Switch>
              <Route exact path="/dashboard">
                <IndexView />
              </Route>
              <Route exact path="/dashboard/profile">
                <ProfileView />
              </Route>
              <Route exact path="/dashboard/dependent">
                <DependentView />
              </Route>
              <Route exact path="/dashboard/billing">
                <BillingView />
              </Route>
              <Route exact path="/dashboard/support">
                <SupportView />
              </Route>
              <Route exact path="/dashboard/invite">
                <InviteView />
              </Route>
              <Route exact path="/dashboard/practice">
                <PracticeView />
              </Route>
              <Route exact path="/dashboard/renewal">
                <RenewalView />
              </Route>
              <Route exact path="/dashboard/pastsubscriptions">
                <PastSubscriptions />
              </Route>
              <Redirect to="/dashboard" />
            </Switch>
          </ViewGrid>
        </Pane>
      </FullScreen>
    </>
  )
}


const DashboardBig = ({ className }: Props) => {
  const { loading, global } = useAppGlobal()

  return loading || !global ? (
    <FullScreen display="flex" justifyContent="center" alignItems="center">
      <Spinner />
    </FullScreen>
  ) : (
    global.meContact.account.status == 'CLOSED' ?
      <>Practice is no longer with the system.</> :
      <>
        <Helmet>
          <title>{global.meContact.account.programName}</title>
          <script async={false} type="text/javascript" id="clearent-hpp" src={`${clearentHPPScript}`}></script>
          <script async={false} src={withPrefix('clearent-init.js')} type="text/javascript" />
        </Helmet>

        <FullScreen className={className} height="100%">
          <AppHeader />
          <AppSidebar />

          <Pane gridArea="view" overflow="scroll" >
            <ViewGrid maxWidth={1000} paddingLeft={8} paddingRight={16} marginBottom={48}>
              <Switch>
                <Route exact path="/dashboard">
                  <IndexView />
                </Route>
                <Route exact path="/dashboard/profile">
                  <ProfileView />
                </Route>
                <Route exact path="/dashboard/dependent">
                  <DependentView />
                </Route>
                <Route exact path="/dashboard/billing">
                  <BillingView />
                </Route>
                <Route exact path="/dashboard/support">
                  <SupportView />
                </Route>
                <Route exact path="/dashboard/invite">
                  <InviteView />
                </Route>
                <Route exact path="/dashboard/practice">
                  <PracticeView />
                </Route>
                <Route exact path="/dashboard/renewal">
                  <RenewalView />
                </Route>
                <Route exact path="/dashboard/pastsubscriptions">
                  <PastSubscriptions />
                </Route>
                <Redirect to="/dashboard" />
              </Switch>
            </ViewGrid>
          </Pane>
        </FullScreen>
      </>
  )
}

const StyledDashboardBig = styled(DashboardBig)`
  display: grid;
  grid-template-rows: 80px 1fr;
  grid-template-columns: 180px 1fr;
  grid-template-areas:
    'header header'
    'sidebar view';
`

const StyledDashboardSmall = styled(DashboardSmall)`
  display: grid;
  grid-template-rows: 60px 1fr;
  grid-template-columns: 20px 1fr;
  grid-template-areas:
    'header header'
    'sidebar view';
`

const ViewGrid = styled(Pane)`
  display: grid;
  grid-template-columns: 1fr;
  align-items: start;
  grid-gap: 16px;
`

export default () => {
  const isSSR = typeof window === 'undefined'
  return (
    <>
      {/* Gatsby SSR Does not yet support suspense */}
      {!isSSR ? (
        <FirebaseProvider>
          <TokenProvider>
            <AuthCheck
              fallback={
                <>
                  <Switch>
                    <Route exact path="/dashboard/reset-password">
                      <ResetPasswordView />
                    </Route>
                    <LoginView />
                    <Redirect from="/dashboard*" to="/dashboard/login" />
                  </Switch>
                </>
              }
            >
              <ApolloProvider>
                <ApolloConsumer />
              </ApolloProvider>
            </AuthCheck>
          </TokenProvider>
        </FirebaseProvider>
      ) : (
        <div />
      )}
    </>
  )
}

const ApolloConsumer = () => {
  const apolloClient = useApolloClient()

  return (
    <ModalProvider apolloClient={apolloClient}>
      <AppGlobalProvider>
        <Dashboard />
      </AppGlobalProvider>
    </ModalProvider>
  )
}
