import gql from 'graphql-tag'

export const GET_BILLING = gql`
  query Billing {
    meContact {
      id
    }
  }
`

export const MY_BILLING_HISTORY = gql`
  query myBillingHistory {
    myBillingHistory {
      amount
        createdAt
        id
        last4
        cardExp
        plan
        status
        isDependent
        paymentDate
        paymentNote
        sequenceNumber
        collectionMethod
        invoiceNumber
        paymentErrorMessage
    }
  }
`




export const UPDATE_CONTACT_SUBSCRIPTION = gql`
  mutation UpdateContactSubscription($id: ID!, $cancel: Boolean, $reactivate: Boolean, $accept: Boolean) {
    updateContactSubscription(id: $id, cancel: $cancel, reactivate: $reactivate, accept: $accept) {
      id
      isCancelled
      isEnabled
      autoRenewal
      status
      startDate
      endDate
    }
  }
`

export const UPDATE_CONTACT_PLAN_SUBSCRIPTION_CARD = gql`
  mutation UpdateContactPlanSubscriptionCard($id: ID!
    $creditCard_token: String!
    $creditCard_type: String!
    $creditCard_desc: String!
    $creditCard_lastfour: String!
    $creditCard_exp: String!
   ) {
    updateContactPlanSubscriptionCard(id: $id,  creditCard_token: $creditCard_token, creditCard_type: $creditCard_type,
      creditCard_desc: $creditCard_desc, creditCard_lastfour: $creditCard_lastfour, creditCard_exp: $creditCard_exp
    ) {
      id
    }
  }
`

export const GET_CONTACT_PLAN_SUBSCRIPTION_FULL = gql`
  query GetContactPlanSubscriptionsFull($id: ID!
   ) {
    getContactPlanSubscriptions(id: $id ) {
      id
      accountId
      fee
      startDate
      endDate
      planTerm
      status      
      plan {
        id
        name
        annualPrice
        annualPriceActive
        monthlyPrice
        monthlyPriceActive
        status
        monthlyDependentPrice
        annualDependentPrice
        dependentDiscount
      }
  }
  }
`

export const MY_SUBSCRIPTIONS = gql`
  query mySubscriptions($isArchived: Boolean){
    mySubscriptions(isArchived: $isArchived) {
      id
      renewalSubscriptionId
      contact {
              secure
              code
              parentId
      }
    }
  }
`