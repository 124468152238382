import React, { } from 'react'
import styled from 'styled-components'

import { useApolloClient } from '@apollo/react-hooks'

import { Pane, Heading } from 'evergreen-ui'

import { Card, CardHeader, Button, CardUpcomingRenewal, isJson } from '@pearly/lib'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { useModal } from 'components/modal-provider'
import { useAppGlobal } from 'components/app-global-provider'
import { auth } from 'firebase'
import { MY_SUBSCRIPTIONS } from 'graphql/_billing'
import * as Types from 'types'

export type Props = {
    className?: string
}

const RenewalView = ({ className }: Props) => {
    const { global } = useAppGlobal()
    if (!global) throw Error('Global App context not available')

    const {
        loading,
        data
    } = useQuery<Types.mySubscriptions>(MY_SUBSCRIPTIONS, {
            context: { secure: true },
            variables: {
                isArchived: false
            }
        }
    );

    console.log(data?.mySubscriptions)
    const apolloClient = useApolloClient()


    return (
        <Pane className={className}>
            <Card gridArea="renewals" padding={0}>
                <CardHeader withButton>
                    <Heading size={500}>Upcoming Renewals</Heading>
                </CardHeader>
                <Pane padding={16}>
                    {
                        data?.mySubscriptions.map((item, i) => {
                            const secureContact =
                                item.contact.secure && isJson(item.contact.secure) && (JSON.parse(item.contact.secure) as Types.SecureContact);
                            if (!secureContact) throw Error('Unable to retrieve secure contact information')
                            return (
                                item?.id ?
                                    <Card key={i} backgroundColor="white" elevation={1} padding={0} margin={16} >
                                        <CardHeader  justifyContent="space-between">
                                            <Heading  size={500}>{`${secureContact.firstName} ${secureContact.lastName} : ${item.contact.parentId ? 'Dependant' : ''}`}</Heading>
                                        </CardHeader>
                                        <CardUpcomingRenewal renewalSubscriptionId={item.id} apolloClient={apolloClient} dependent={false} isAdmin={false}></CardUpcomingRenewal>
                                    </Card> : <></>
                            )
                        })
                    }

                    {/*  */}
                </Pane>
            </Card>
        </Pane>
    )
}

export default styled(RenewalView)`
  display: grid;
  grid-template-rows: auto auto auto;
  grid-template-columns: 1fr;
  grid-template-areas: 'renewals';
  grid-gap: 16px;
`
