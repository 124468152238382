import gql from 'graphql-tag'

export const UPDATE_CONTACT_IDENTITY = gql`
  mutation UpdateContactIdentity(
    $currentEmail: String!
    $newEmail: String!
    $phone: String!
    $firstName: String!
    $lastName: String!
  ) {
    updateContactIdentity(
      currentEmail: $currentEmail
      newEmail: $newEmail
      phone: $phone
      firstName: $firstName
      lastName: $lastName
    )
  }
`
