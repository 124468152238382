import React from 'react'

import { logout } from 'utility/auth'

import { Pane, Popover, Menu, Position, Heading } from 'evergreen-ui'

import { Link, colors } from '@pearly/lib'
import HeaderButton from 'components/header-button'

import { useAppGlobal } from 'components/app-global-provider'

const AppHeader = () => {
  const { global } = useAppGlobal()
  if (!global) throw Error('Global App context not available')
  const contact = global.meContact

  return (
    <Pane
      gridArea="header"
      paddingX={32}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      position="relative"
    >
      <Pane lineHeight={0}>
        {contact.account.logoUrl ? (
          <img src={contact.account.logoUrl} height="48px" />
        ) : (
          <Heading size={700}>{contact.account.programName}</Heading>
        )}
      </Pane>
      <div> {/*
        <Link to="/dashboard/support" inline>
          <HeaderButton color={colors.blue.base} iconAfter={['far', 'comment-alt-lines']}>
            Support
          </HeaderButton>
        </Link> */}
        <Popover
          position={Position.BOTTOM_RIGHT}
          content={
            <Menu>{/*
              <Menu.Group>
                <Menu.Item>Profile</Menu.Item>
                <Menu.Item>Settings</Menu.Item>
              </Menu.Group>
               <Menu.Divider /> */ }
              <Menu.Group>
                <Menu.Item icon="log-out" onSelect={() => logout()}>
                  Log Out
                </Menu.Item>
              </Menu.Group>
            </Menu>
          }
        >
          <HeaderButton color={colors.blue.base} marginLeft={4} iconAfter={['fas', 'user-circle']}>
            {contact.secure.firstName} {contact.secure.lastName}
          </HeaderButton>
        </Popover>
      </div>
    </Pane>
  )
}

export default AppHeader
