import React from 'react'
import styled from 'styled-components'

import { Pane, Heading, Text } from 'evergreen-ui'

import { Card, CardHeader, InviteFields } from '@pearly/lib'

export type Props = {
  className?: string
}

const InviteView = ({ className }: Props) => {
  return (
    <Pane className={className}>
      <Card gridArea="invite" padding={0}>
        <CardHeader>
          <Heading size={500}>Invite-A-Friend</Heading>
        </CardHeader>

        <Pane padding={16} paddingBottom={40} display="flex" flexDirection="column" alignItems="center">
          <Text display="block" marginBottom={16}>
            Enter your friend’s mobile number or email below to share an invite to your dental nembership plan. For
            every friend who signs-up, you'll get a $20 Amazon Gift Card.
          </Text>
          <InviteFields />
        </Pane>
      </Card>
    </Pane>
  )
}

export default styled(InviteView)`
  display: grid;
  grid-template-rows: auto ;
  grid-template-columns: 1fr;
  grid-template-areas: 'tickinviteet'
  grid-gap: 16px;
`
