import React, { useState } from 'react'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { resetMemberPassword } from 'utility/auth'

import { TextInput, FormError, Icon } from '@pearly/lib'
import { Pane, Text, Dialog } from 'evergreen-ui'

export type Props = {
  isShown: boolean
  setIsShown: React.Dispatch<React.SetStateAction<boolean>>
}

const ResetPasswordDialog = ({ isShown, setIsShown }: Props) => {
  const [isLoading, setIsLoading] = useState(false)

  return (
    <Formik
      initialValues={{ email: '' }}
      onSubmit={({ email }) => {
        resetMemberPassword(email,
          () => setIsShown(false),
          () => setIsLoading(false))
      }}
      validationSchema={Yup.object({
        email: Yup.string()
          .email('Invalid email address')
          .required('Email is required')
      })}
    >
      {formik => (
        <Dialog
          isShown={isShown}
          title="Reset password"
          onCloseComplete={() => {
            setIsShown(false)
            setIsLoading(false)
          }}
          isConfirmLoading={isLoading}
          onConfirm={() => formik.handleSubmit()}
          confirmLabel={isLoading ? 'Resetting...' : 'Reset Password'}
        >
          <Pane display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Icon icon={['fad', 'unlock']} color="primary" size="3x" marginY={16} />
            <Text size={500} marginBottom={16}>
              Let's reset your password
            </Text>

            <Form>
              <TextInput name="email" type="email" marginBottom={0} placeholder="john@harris.com" />
              <FormError />
            </Form>
          </Pane>
        </Dialog>
      )}
    </Formik>
  )
}

export default ResetPasswordDialog
