import React from 'react'

import { Pane, Paragraph, Heading, Text } from 'evergreen-ui'

import { Card, CardHeader, Link, OfficeCard, DentistCard, prettyPhoneNumber } from '@pearly/lib'
import { useAppGlobal } from 'components/app-global-provider'

const PracticeCard = () => {
  const { global } = useAppGlobal()
  if (!global) throw Error('Global App context not available')
  const account = global.meContact.account
  if (!account) throw Error('Contact data incomplete')

  return (
    <Card padding={0}>
      <CardHeader justifyContent="space-between">
        <Heading size={500}>{account.programName}</Heading>
        <Text color="muted" marginLeft={8}>
          Code: {account.code.toUpperCase()}
        </Text>
      </CardHeader>
      <Pane padding={16}>
        <Paragraph marginBottom={16}>Contact us to book an appointment or discuss treatments:</Paragraph>
        <Pane display="flex" justifyContent={account.siteUrl ? 'space-between' : 'center'} paddingX={32}>
          <Link size={500} href={`tel:${account.sitePhone}`}>
            {prettyPhoneNumber(account.sitePhone)}
          </Link>
          {account.siteUrl && (
            <Link size={500} href={account.siteUrl} textDecoration="underline" target="_blank">
              Visit Website
            </Link>
          )}
        </Pane>
      </Pane>

      <Pane padding={16} elevation={0}>
        <Heading size={500} marginBottom={16}>
          Offices
        </Heading>
        {account.offices.map((office, i) => (
          <OfficeCard office={office} key={i} marginTop={i === 0 ? 0 : 8} />
        ))}
      </Pane>
      <Pane padding={16} elevation={0}>
        <Heading size={500} marginBottom={16}>
          Dentists
        </Heading>
        {account.dentists.map((dentist, i) => (
          <DentistCard dentist={dentist} key={i} marginTop={i === 0 ? 0 : 8} />
        ))}
      </Pane>
    </Card>
  )
}

export default PracticeCard
