import React  from 'react'
import { useHistory } from "react-router-dom"
import { useModal } from 'components/modal-provider'

import { colors } from '@pearly/lib'
import HeaderButton from 'components/header-button'
import { logout } from 'utility/auth'

import { Pane, Popover, Position, Menu, Button, Heading, Text } from 'evergreen-ui'


import { useAppGlobal } from 'components/app-global-provider'

export const AppHeaderSmall = () => {
    const { global } = useAppGlobal()
    if (!global) throw Error('Global App context not available')
    const contact = global.meContact
    let history = useHistory();
    const showSendInviteDialog = useModal('sendInvite')
  
    return (
      <Pane
        gridArea="header"
        paddingX={0}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        position="relative"
      >
        <Pane>
          <Popover
            position={Position.BOTTOM_LEFT}
            content={ ({close}) => (
              <Menu>
                <Menu.Group>
                  <Menu.Item onSelect={() => {history.push("/dashboard"); close() }}>
                    Home
                  </Menu.Item>
                  <Menu.Item onSelect={() => {history.push("/dashboard/profile"); close() }}>
                    Profile
                  </Menu.Item>
                  <Menu.Item onSelect={() => {history.push("/dashboard/dependent"); close() }}>
                    Dependent
                  </Menu.Item>
                  <Menu.Item onSelect={() => {history.push("/dashboard/billing"); close() }}>
                    Billing
                  </Menu.Item>
                  <Menu.Item onSelect={() => {history.push("/dashboard/practice"); close() }}>
                    Practice
                  </Menu.Item>
                  <Menu.Item onSelect={() => {history.push("/dashboard/support"); close() }}>
                    Support
                  </Menu.Item>
                  <Menu.Item onSelect={() => {close(); showSendInviteDialog() }}>
                    Invite Friend
                  </Menu.Item>
                </Menu.Group>
              </Menu> )          
            }>
            <Button marginRight={16} iconBefore="menu" appearance="default" marginLeft={15} paddingRight={3} marginBottom={0}></Button>
          </Popover>
        </Pane>
        <Pane lineHeight={0}>
          {contact.account.logoUrl ? (
            <img src={contact.account.logoUrl} height="48px" />
          ) : (
            <Heading size={500}>{contact.account.programName}</Heading>
          )}
        </Pane>
        <div>
          <Popover
            position={Position.BOTTOM_RIGHT}
            content={
                <>
                <Text padding={16}>{contact.secure.firstName} {contact.secure.lastName} </Text>
                <Menu>
                    <Menu.Group>
                    <Menu.Item icon="log-out" onSelect={() => logout()}>
                        Log Out
                    </Menu.Item>
                    </Menu.Group>
                </Menu>
              </>
            }
          >
            <HeaderButton color={colors.blue.base} marginLeft={4} iconAfter={['fas', 'user-circle']}>         
            </HeaderButton>
          </Popover>
        </div>
      </Pane>
    )
  }
  