import gql from 'graphql-tag'

export const GET_APP_GLOBAL = gql`
  query AppGlobal {
    meContact {
      id
      code
      planTerm
      secure
      account {
        id
        code
        logoUrl
        programName
        sitePhone
        siteUrl
        clearentPaymentKey
        offices {
          id
          name
          address1
          address2
          city
          state
          zip
          phone
        }
        dentists {
          id
          salutation
          name
          degree
        }
        status
      }
      subscriptions {
        id
        creditCard_token
        creditCard_type
        creditCard_lastfour
        creditCard_exp
        creditCard_desc
        startDate
        endDate
        isEnabled
        isCancelled
        status
        collectingMethod
        plan {
          id
          name
          additionalTerms
          annualPrice
          dependentDiscount
          discounts {
            id
            category
            value
          }
          globalDiscount
          globalDiscountActive
          monthlyPrice
          showValue
          treatments {
            id
            code
            name
            quantity
            value
          }          
        }
        planTerm
        renewalSubscriptionId
      }
    }
  }
`
