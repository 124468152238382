import React from 'react'
import styled from 'styled-components'

import { Pane, Heading, Text } from 'evergreen-ui'

import { Card, CardHeader, InviteFields } from '@pearly/lib'
import PracticeCard from 'components/practice-card'


export type Props = {
  className?: string
}

const PracticeView = ({ className }: Props) => {
  return (
    <Pane className={className}>
        <PracticeCard />
    </Pane>
  )
}

export default styled(PracticeView)`
  display: grid;
  grid-template-rows: auto ;
  grid-template-columns: 1fr;
  grid-template-areas: 'tickinviteet'
  grid-gap: 16px;
`
