import React, { useEffect, useState } from 'react'
import * as Types from 'types'
import styled from 'styled-components'
import moment from 'moment'

import { useMutation, useQuery } from '@apollo/react-hooks'
import {  UPDATE_CONTACT_SUBSCRIPTION, UPDATE_CONTACT_PLAN_SUBSCRIPTION_CARD, MY_BILLING_HISTORY } from 'graphql/_billing'

import { Pane, Heading, Label, toaster } from 'evergreen-ui'

import { Card, CardHeader, Button, Spinner, PayButton, SavedTokenInput, PaymentHistoryTable } from '@pearly/lib'

import { useModal } from 'components/modal-provider'
import { useAppGlobal } from 'components/app-global-provider'
import { auth } from 'firebase'

export type Props = {
  className?: string
}

const AccountView = ({ className }: Props) => {
  const showConfirmDialog = useModal('confirm')
  const accountToken = () => auth().currentUser?.getIdToken()

  const { global } = useAppGlobal()
  if (!global) throw Error('Global App context not available')
  const contactId = global.meContact.id


 // const { loading, error } = useQuery<Types.Billing>(GET_BILLING)

  const {
    data: billingHistoryData
  } = useQuery<Types.myBillingHistory>(MY_BILLING_HISTORY)


  const [updateSubscription, updateSubscriptionStatus] = useMutation<
    Types.UpdateContactSubscription,
    Types.UpdateContactSubscriptionVariables
  >(UPDATE_CONTACT_SUBSCRIPTION)

  const [updatePlanSubscriptionCard, updatePlanSubscriptionCardStatus] = useMutation<
    Types.UpdateContactPlanSubscriptionCard,
    Types.UpdateContactPlanSubscriptionCardVariables
  >(UPDATE_CONTACT_PLAN_SUBSCRIPTION_CARD)

  useEffect(() => {
    if (updatePlanSubscriptionCardStatus.error) toaster.danger('Unable to update plan with credit card')
    else if (updatePlanSubscriptionCardStatus.data && !updatePlanSubscriptionCardStatus.loading) {
      toaster.success(`Credit card updated!`)
    }
  }, [updatePlanSubscriptionCardStatus])

  useEffect(() => {
    if (updateSubscriptionStatus.error) toaster.danger('Unable to update plan')
    else if (updateSubscriptionStatus.data && !updateSubscriptionStatus.loading) {
      const sub = updateSubscriptionStatus.data.updateContactSubscription
      const updatedData = { ...subscription }
      updatedData.active = sub?.isEnabled
      updatedData.cancelled = sub?.isCancelled
      setSubscription(updatedData)
      if (sub?.isCancelled) {
        toaster.danger(`Plan membership has been cancelled`)
      } else {
        toaster.success(`Plan membership has been activated!`)
      }
    }
  }, [updateSubscriptionStatus])

  const paymentKey = global.meContact.account.clearentPaymentKey
  const currentSub = global.meContact.subscriptions?.find(() => true)

  const [subscription, setSubscription] = useState({
    active: currentSub?.isEnabled,
    cancelled: currentSub?.isCancelled,
    endDate: currentSub?.endDate
  })

  const [creditCardToken, setCreditCardToken] = useState<SavedTokenInput | undefined>({
    cardType: currentSub?.creditCard_type ?? '',
    tokenId: currentSub?.creditCard_token ?? '',
    lastFourDigits: currentSub?.creditCard_lastfour ?? '',
    expDate: currentSub?.creditCard_exp ?? ''
  })


  let charges: {
    createdAt: string,
    paymentDate: string | null,
    amount: number,
    last4: string,
    cardExp: string | null,
    plan: string,
    status: string,
    isDependent: boolean,
    id: string,
    invoiceNumber: string,
    paymentErrorMessage: string | null
  }[] = []


  billingHistoryData?.myBillingHistory?.map(hist => {
    charges.push({
      createdAt: String(hist.createdAt) ?? moment.now(),
      paymentDate: hist.paymentDate?moment(hist.paymentDate)?.format('M/D/YYYY'): null,
      amount: Number(hist.amount),
      last4: hist.last4 ?? '',
      cardExp: hist.cardExp,
      plan: hist.plan ?? '',
      status: hist.status ?? '',
      isDependent: hist.isDependent ?? false,
      id: hist.id ?? '',
      invoiceNumber: hist.invoiceNumber ?? '',
      paymentErrorMessage: hist.paymentErrorMessage
    })
  })

  const updateCreditCardToken = (token: SavedTokenInput) => {
    if (currentSub != null) {
      updatePlanSubscriptionCard({
        variables: {
          id: currentSub.id,
          creditCard_token: token.tokenId,
          creditCard_type: token.cardType,
          creditCard_lastfour: token.lastFourDigits,
          creditCard_desc: '',
          creditCard_exp: token.expDate
        }
      })
      setCreditCardToken(token)
    }
  }
  const activatePlan = (contactId: string) => {
    if (!creditCardToken?.tokenId) {
      toaster.danger("Cannot activate without credit card.")
      return
    }

    updateSubscription({ variables: { id: currentSub!.id, reactivate: true } })
  }

  return (
    <Pane className={className}>
      { !currentSub
        ? (
          <Spinner providerView />
        ) : (
          <>
            <Card gridArea="billing" padding={0}>
              <CardHeader justifyContent="space-between">
                <Heading size={500}>Billing Details</Heading>
              </CardHeader>
              <Pane padding={16}>
                <Pane display="flex" justifyContent="space-between" marginBottom={8}>
                  <Label display="block">Payment Method</Label>
                  <Label display="block">
                    {subscription.cancelled ? 'Termination' : 'Renewal'} Date:{' '}
                    {moment.utc(subscription.endDate).add(1, "days").format('M/D/YYYY')}
                  </Label>
                </Pane>
                <Card backgroundColor="white" elevation={0} padding={0} overflow="hidden" marginBottom={16}>
                  <PayButton id="billing-pay" paymentKey={paymentKey ?? ''} creditCardToken={creditCardToken} callBack={updateCreditCardToken} />
                </Card>
              </Pane>
              <Pane elevation={0} padding={16}>
                {subscription.cancelled ? (
                  <Button
                    isLoading={updateSubscriptionStatus.loading}
                    height={40}
                    intent="success"
                    onClick={() => activatePlan(contactId)}
                  >
                    Reactivate
                  </Button>
                ) : (
                  <Button
                    isLoading={updateSubscriptionStatus.loading}
                    height={40}
                    intent="danger"
                    onClick={() => {
                      showConfirmDialog({
                        body: `Are you sure you want to cancel your plan?  It will no longer be active as of ${moment
                          .utc(subscription?.endDate).add(1, "days")
                          .format('M/D/YYYY')}.`,
                        confirm: () => {
                          updateSubscription({ variables: { id: currentSub!.id, cancel: true } })
                        }
                      })
                    }}
                  >
                    Cancel
                  </Button>
                )}
              </Pane>
            </Card>
            <Card gridArea="history" padding={0}>
              <CardHeader>
                <Heading size={500}>Billing History </Heading>
              </CardHeader>
              <Pane>
                <PaymentHistoryTable tanentType='member' hostUrl={process.env.GATSBY_APP_URL_HOST ?? ''} accountToken={accountToken} charges={charges} />
              </Pane>
            </Card>
          </>
        )}
    </Pane>
  )
}

export default styled(AccountView)`
  display: grid;
  grid-template-rows: auto auto auto;
  grid-template-columns: 1fr;
  grid-template-areas: 'billing' 'dependents' 'history';
  grid-gap: 16px;
`
