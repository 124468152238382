import React from 'react'

import { Pane, TabNavigation, SidebarTab } from 'evergreen-ui'

import { SidebarLink, Link } from '@pearly/lib'
import { useModal } from 'components/modal-provider'
import { useAppGlobal } from 'components/app-global-provider'

const AppSidebar = () => {
  const { global } = useAppGlobal()

  const showSendInviteDialog = useModal('sendInvite')
  const showEnrollMemberSheet = useModal('enrollMember')

  return (
    <Pane gridArea="sidebar" display="flex" flexDirection="column">
      <TabNavigation
        flexGrow={1}
        margin={16}
        marginTop={0}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
     >
        <div >
          <SidebarLink to="/dashboard" match="/dashboard" paddingLeft={35}>
            Home
          </SidebarLink>
          <SidebarLink to="/dashboard/profile" match="/dashboard/profile" paddingLeft={35}>
            Profile
          </SidebarLink>
          <SidebarLink to="/dashboard/dependent" match="/dashboard/dependent"paddingLeft={35}>
            Dependent
          </SidebarLink>
          <SidebarLink to="/dashboard/billing" match="/dashboard/billing"paddingLeft={35}>
            Billing
          </SidebarLink>
          <SidebarLink to="/dashboard/practice" match="/dashboard/practice"paddingLeft={35}>
            Practice
          </SidebarLink>
          <SidebarLink to="/dashboard/support" match="/dashboard/support"paddingLeft={35}>
            Support
          </SidebarLink>
          <SidebarLink to="/dashboard/renewal" match="/dashboard/renewal" paddingLeft={35}>
            My Renewal
          </SidebarLink>
          <SidebarLink to="/dashboard/pastsubscriptions" match="/dashboard/pastsubscriptions" paddingLeft={35}>
            History
          </SidebarLink>
        </div>
        <Pane display="flex" flexDirection="column" textAlign="center">
          <SidebarTab
            paddingLeft={0}
            size={500}
            color="muted"
            onSelect={() => showEnrollMemberSheet({ parentId: global?.meContact.id, memberApp: true })}
          >
            Enroll Dependent
          </SidebarTab>
          <SidebarTab paddingLeft={0} size={500} color="muted" onSelect={() => showSendInviteDialog()}>
            Invite Friend
          </SidebarTab>
          <Link href="https://www.planforhealth.com/legal/links" target="_blank">
            <SidebarTab paddingLeft={0} size={500} color="muted" marginBottom={8}>
              Legal
            </SidebarTab>
          </Link>
        </Pane>
      </TabNavigation>
    </Pane>
  )
}

export default AppSidebar
