import gql from 'graphql-tag'


export const GET_OFFICES = gql`
  query firstOfficeForContact {
    firstOfficeForContact {
      id
      name
      address1
      address2
      city
      state
      zip
      phone
      email
    }
  }
`