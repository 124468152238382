import React, {  } from 'react'
import styled from 'styled-components'

import { useApolloClient } from '@apollo/react-hooks'

import { Pane, Heading } from 'evergreen-ui'

import { Card, CardHeader, Button, DependentCardList } from '@pearly/lib'

import { useModal } from 'components/modal-provider'
import { useAppGlobal } from 'components/app-global-provider'
import { auth } from 'firebase'

export type Props = {
  className?: string
}

const DependentView = ({ className }: Props) => {
  const { global } = useAppGlobal()
  if (!global) throw Error('Global App context not available')
  const contactId = global.meContact.id

  const accountToken = ()=> auth().currentUser?.getIdToken()

  const apolloClient = useApolloClient()

  const showEnrollMemberSheet = useModal('enrollMember')

  return (
    <Pane className={className}>
            <Card gridArea="dependents" padding={0}>
              <CardHeader withButton>
                <Heading size={500}>Dependent Plans</Heading>
                <Button iconBefore={['fas', 'user-plus']} onClick={() => showEnrollMemberSheet({ parentId: contactId, memberApp: true })}>
                  Enroll Dependent
              </Button>
              </CardHeader>
              <Pane padding={16}>
                <DependentCardList isAdmin={false} apolloClient={apolloClient} contactId={contactId} accountToken={accountToken}/>
              </Pane>
            </Card>
    </Pane>
  )
}

export default styled(DependentView)`
  display: grid;
  grid-template-rows: auto auto auto;
  grid-template-columns: 1fr;
  grid-template-areas: 'dependents';
  grid-gap: 16px;
`
