import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { useAuth } from 'reactfire'

import { Pane, Paragraph } from 'evergreen-ui'
import { loginWithEmailAndPassword } from 'utility/auth'

import { useMediaQueryContext, FullScreen, Button, Link, DoubleTextInput, FormError, Spinner } from '@pearly/lib'
import ResetPasswordDialog from 'components/_dialogs/reset-password-dialog'
import ContactUsDialog from 'components/_dialogs/contactUs-dialog'
import loginIllustration from 'data/img/login-illustration.png'
import logo from 'data/img/TB-Logos-Final_PlanForHealth.png'


const LoginPage = () => {
  const auth = useAuth()
  useEffect(() => {
    auth.tenantId = process.env.GATSBY_IDP_TENANTID ?? null
  }, [auth])

  const { isTablet } = useMediaQueryContext()
  const [loading, setLoading] = useState(false)
  const [showResetPasswordDialog, setShowResetPasswordDialog] = useState(false)
  const [contactUsDialog, setContactUsDialog] = useState(false)

  return (
    <>
      <Helmet>
        <title>Patient Login</title>
      </Helmet>
      <ContactUsDialog isShown={contactUsDialog} setIsShown={setContactUsDialog} />
      <ResetPasswordDialog isShown={showResetPasswordDialog} setIsShown={setShowResetPasswordDialog} />
      <FullScreen padding={24} display="flex" justifyContent="center" alignItems="center">
        <Pane width={320} marginRight={10}>
          {loading ? (
            <Spinner />
          ) : (
              <>
                <Pane textAlign="center" >
                  <Pane textAlign="center" width="50%">

                    <img src={logo} height="52px" alt="logo" />
                  </Pane>
                  <Paragraph size={500} fontWeight={500} color="muted" marginTop={20} marginBottom={10}>
                    Log in to your Member Portal.
                </Paragraph>
                </Pane>

                {/* Email / Password Login */}
                <Formik
                  initialValues={{ email: '', password: '' }}
                  onSubmit={({ email, password }) => {
                    loginWithEmailAndPassword(setLoading, email, password)
                  }}
                  validationSchema={Yup.object({
                    email: Yup.string()
                      .email('Invalid email address')
                      .required('Email is required'),
                    password: Yup.string()
                      .min(8, 'Password must be at least 8 characters')
                      .required('Password is required')
                  })}
                >
                  <Form>
                    <DoubleTextInput
                      name="email"
                      type="email"
                      width="100%"
                      placeholder="john@harris.com"
                      icon={['fal', 'envelope']}
                      half="top"
                    />
                    <DoubleTextInput
                      name="password"
                      type="password"
                      placeholder="Password"
                      width="100%"
                      marginBottom={0}
                      icon={['fal', 'lock']}
                      half="bottom"
                    />
                    <FormError marginBottom={8} />
                    <Button
                      className="loginButton"
                      type="submit"
                      appearance="primary"
                      height={48}
                      width="100%"
                      justifyContent="center"
                      marginBottom={8}
                    >
                      Log In
                  </Button>
                    <Paragraph textAlign="center" fontWeight={700} marginTop={12}>
                      Need Help?{' '}
                      <Link fontWeight={700} inline onClick={() => setShowResetPasswordDialog(true)}>
                        Reset Password
                    </Link>{' '}
                      or{' '}
                      {/* <Link fontWeight={700} inline href="https://www.planforhealth.com/help-center">
                        Contact Us
                    </Link> */}
                    <Link fontWeight={700} inline onClick={() => {setContactUsDialog(true)}}>
                        Contact Us
                    </Link>
                    </Paragraph>
                  </Form>
                </Formik>
              </>
            )}
        </Pane>

        <Pane width="500px" marginRight={10} paddingX={40} display={isTablet ? 'none' : 'block'}>
          <img src={loginIllustration} width="100%" alt="Growth Services Hero" />
        </Pane>
      </FullScreen>
    </>
  )
}

export default LoginPage
