import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'

import { useLocation, useHistory } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'

import { Pane, Heading, Text } from 'evergreen-ui'

import { FullScreen, Link, Button, Card, CardHeader, DoubleTextInput, useMediaQueryContext, useToken, FormError } from '@pearly/lib'

import logo from 'data/img/TB-Logos-Final_PlanForHealth.png'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import queryString from 'query-string'
import { resetedPassword, verifyMemberPasswordResetCode } from 'utility/auth'

export type Props = {
    className?: string
}

const ResetPasswordView = ({ className }: Props) => {
    const history = useHistory()
    const [userEmail, setUserEmail] = useState('')

    const params: any = queryString.parse(history.location.search);

    verifyMemberPasswordResetCode(
        params?.oobCode,
        (user) => setUserEmail(user),
        () => { }
    )

    const location = useLocation()
    const { pathname } = location
    const { isMobile } = useMediaQueryContext()

    const [isForward, setIsForward] = useState(true)
    const exitAnimationDuration = 0.15

    useEffect(() => {
        // Was unable to implement using refs because onboarding body is being unmounted / mounted
        const timeout = setTimeout(
            () => document.querySelectorAll('#onboarding-body').forEach(el => el.scrollTo({ top: 0, behavior: 'auto' })),
            exitAnimationDuration * 1000
        )
        return () => clearTimeout(timeout)
    }, [pathname])

    const variants = {
        initial: {
            opacity: 0,
            x: isMobile ? 0 : isForward ? 180 : -180
        },
        enter: {
            opacity: 1,
            x: 0,
            transition: {
                duration: 0.5,
                delay: 0.05
            }
        },
        exit: {
            opacity: 0,
            transition: {
                duration: exitAnimationDuration
            }
        }
    }


    return (
        <FullScreen className={className}>
            <Pane gridArea="header" display="flex" justifyContent="space-between" alignItems="center">
                <Pane width={isMobile ? '120px' : '180px'} textAlign="center" paddingY={16} cursor={'pointer'}>
                    <img src={logo} alt="logo" height={isMobile ? '28px' : '37px'} onClick={() => { history.push('/') }} />
                </Pane>
                <Pane height={isMobile ? '100%' : '50px'} marginRight={8} display="flex" alignItems="center">
                    <Link href="tel:8007722160">
                        <Button appearance="minimal" iconBefore={['fas', 'phone']}>
                            (800) 772-2160
                        </Button>
                    </Link>
                    <Link href="mailto:support@trubludirect.com" hidden={isMobile} marginLeft={8}>
                        <Button appearance="minimal" iconBefore={['fas', 'envelope']}>
                            support@trubludirect.com
                        </Button>
                    </Link>
                </Pane>
            </Pane>
            <Pane
                id="onboarding-body"
                gridArea="body"
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="center"
                overflow="scroll"
            >
                <Pane
                    width="400px"
                    maxWidth="100vw"
                    paddingX={16}
                    paddingTop={isMobile ? 8 : 0}
                    paddingBottom={isMobile ? 128 : 16}
                    display="flex"
                    justifyContent="center"
                >
                    <AnimatePresence initial={false} exitBeforeEnter>
                        <motion.div
                            key={pathname}
                            variants={variants}
                            initial="initial"
                            animate="enter"
                            exit="exit"
                            style={{ width: '100%' }}
                        >
                            {userEmail ?

                                <Card padding={0}>
                                    <CardHeader>
                                        <Heading size={500} flexGrow={1} textAlign="center">
                                            Reset your password
                                        </Heading>
                                    </CardHeader>
                                    <Formik
                                        initialValues={
                                            { password: '', passwordConfirmation: '' }
                                        }
                                        onSubmit={({ password, passwordConfirmation }) => {
                                            resetedPassword(
                                                params?.oobCode,
                                                password,
                                                () => {
                                                    setTimeout(() => {
                                                        history.push('/')
                                                    }, 2000)
                                                },
                                                () => { }
                                            )
                                        }}
                                        validationSchema={Yup.object({
                                            password: Yup.string()
                                                .required('Password is required')
                                                .min(8, 'Password must be at least 8 characters'),
                                            passwordConfirmation: Yup.string()
                                                .required('Password Confirmation is required')
                                                .oneOf([Yup.ref('password'), null], 'Passwords must match')
                                        })}
                                    >
                                        <Form onScroll={ () => {var a:any = document.activeElement; a?.blur();}}>
                                            <Pane padding={24} paddingBottom={8} display="flex" flexDirection="column" alignItems="center">
                                                <Text size={500} marginBottom={16}>
                                                    {userEmail}
                                                </Text>
                                                <Pane paddingX={24} width="100%">
                                                    <DoubleTextInput
                                                        half="bottom"
                                                        name="password"
                                                        type="password"
                                                        placeholder="New Password"
                                                        width="100%"
                                                        icon={['fal', 'lock']}
                                                    />
                                                    <DoubleTextInput
                                                        half="bottom"
                                                        name="passwordConfirmation"
                                                        type="password"
                                                        placeholder="Confirm Password"
                                                        width="100%"
                                                        icon={['fal', 'lock']}
                                                    />
                                                </Pane>
                                                <FormError />
                                            </Pane>

                                            <Pane zIndex={1} elevation={0} padding={16} flexShrink="0" display="flex" justifyContent="flex-end">
                                                <Button
                                                    type="submit"
                                                    appearance="primary"
                                                    height={48}
                                                    justifyContent="center"
                                                >
                                                    Reset
                                                </Button>
                                            </Pane>
                                        </Form>
                                    </Formik>
                                </Card>
                                :
                                <Card padding={0}>
                                    <CardHeader>
                                        <Heading size={500} flexGrow={1} textAlign="center">
                                            Try resetting your password again
                                        </Heading>
                                    </CardHeader>
                                    <Pane padding={24} paddingBottom={8} display="flex" flexDirection="column" alignItems="center">
                                        <Text size={500} marginBottom={16}>
                                            Your request to reset your password has expired or the link has already been used
                                        </Text>
                                    </Pane>
                                </Card>
                            }
                        </motion.div>
                    </AnimatePresence>
                </Pane>
            </Pane>
        </FullScreen>
    )
}

export default styled(ResetPasswordView)`
  display: grid;
  grid-template-rows: auto auto 1fr;
  grid-template-columns: 100%;
  height: 100vh;
  grid-template-areas:
    'header'
    'progress-bar'
    'body';
`
