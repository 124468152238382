import React, { useState } from 'react'

import {  Icon } from '@pearly/lib'
import { Pane, Text, Dialog } from 'evergreen-ui'

export type Props = {
  isShown: boolean
  setIsShown: React.Dispatch<React.SetStateAction<boolean>>
}

const ContactUsDialog = ({ isShown, setIsShown }: Props) => {
  return (
    <Dialog
      isShown={isShown}
      title="Contact Us"
      onCloseComplete={() => {
        setIsShown(false)
      }}
      hasFooter={false}
    >
      <Pane display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Icon icon={['far', 'comment-alt-lines']} color="primary" size="3x" marginY={16} />
        <Text size={500} marginBottom={16}>
          Please contact your dental practice directly for support or questions.
        </Text>
      </Pane>
    </Dialog>
  )
}

export default ContactUsDialog
