import React, { createContext, useContext } from 'react'

import { CreditCardProvider } from '@pearly/lib'

import { useQuery } from '@apollo/react-hooks'
import { GET_APP_GLOBAL } from 'graphql/_app-global'
import * as Types from 'types'

type GlobalContext = {
  loading: boolean
  global?: {
    meContact: Omit<Types.AppGlobal_meContact, 'secure'> & { secure: Types.SecureContact }
  }
}

const Context = createContext<GlobalContext>({ loading: true, global: undefined })
export const useAppGlobal = () => useContext(Context)

export const AppGlobalProvider = ({ children }: { children: React.ReactNode }) => {
  const { loading, data } = useQuery<Types.AppGlobal>(GET_APP_GLOBAL, { context: { secure: true } })

  const secureData = data?.meContact
    ? {
        meContact: {
          ...data.meContact,
          secure: JSON.parse(data.meContact.secure) as Types.SecureContact
        }
      }
    : undefined

  const creditCardEngine = {
     engine: "Clearent",
     url: "",
     paymentKey: data?.meContact?.account?.clearentPaymentKey??""
  }

  return <CreditCardProvider engine={creditCardEngine}>
  <Context.Provider value={{ loading, global: secureData }}>{children}</Context.Provider>
  </CreditCardProvider>
}
