import React, { useEffect } from 'react'
import * as Types from 'types'
import styled from 'styled-components'
import moment from 'moment'

import { useMutation } from '@apollo/react-hooks'
import { UPDATE_CONTACT_PROFILE } from 'graphql/secure-operations'
import { UPDATE_CONTACT_IDENTITY } from 'graphql/common'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { Pane, Heading, toaster } from 'evergreen-ui'

import { Card, CardHeader, Button, MemberFields, FormError, VgsWrap } from '@pearly/lib'

import { useAppGlobal } from 'components/app-global-provider'
import { useModal } from 'components/modal-provider'

export type Props = {
  className?: string
}

const AccountView = ({ className }: Props) => {
  const showConfirmDialog = useModal('confirm')

  const { global } = useAppGlobal()
  if (!global) throw Error('Global App context not available')
  const contact = global.meContact

  const [updateProfile, updateProfileStatus] = useMutation<
    Types.UpdateContactProfile,
    Types.UpdateContactProfileVariables
  >(UPDATE_CONTACT_PROFILE, {
    context: { secure: true }
  })

  const [updateIdentity, updateIdentityStatus] = useMutation<
    Types.UpdateContactIdentity,
    Types.UpdateContactIdentityVariables
  >(UPDATE_CONTACT_IDENTITY)

  useEffect(() => {
    if (!updateProfileStatus.loading && updateProfileStatus.data) {
      toaster.success(`Profile successfully updated!`)
    }
  }, [updateProfileStatus])

  return (
    <Pane className={className}>
      <Card gridArea="profile" padding={0}>
        <Formik
          initialValues={{
            firstName: contact.secure.firstName,
            lastName: contact.secure.lastName,
            email: contact.secure.email,
            phone: contact.secure.phone,
            dobMonth: contact.secure.dobMonth,
            dobDay: contact.secure.dobDay,
            dobYear: contact.secure.dobYear,
            perio: contact.secure.perio,
            address1: contact.secure.address1 ?? '',
            address2: contact.secure.address2 ?? '',
            city: contact.secure.city ?? '',
            state: contact.secure.state ?? ('' as Types.State),
            zip: contact.secure.zip ?? ''
          }}
          onSubmit={fields => {
            showConfirmDialog({
              body: 'Are you sure you want to make changes?',
              confirm: async () => {
                try {
                  if (
                    fields.email !== contact.secure.email ||
                    fields.phone !== contact.secure.phone ||
                    fields.firstName !== contact.secure.firstName ||
                    fields.lastName !== contact.secure.lastName
                  ) {
                    await updateIdentity({
                      variables: {
                        currentEmail: contact.secure.email,
                        newEmail: fields.email,
                        phone: fields.phone,
                        firstName: fields.firstName,
                        lastName: fields.lastName
                      }
                    })
                  }

                  await updateProfile({
                    variables: {
                      id: contact.id,
                      secure: VgsWrap<Types.SecureContact>(fields)
                    }
                  })
                } catch (err) {
                  toaster.danger(err.message.replace('GraphQL error: ', ''))
                }
              }
            })
          }}
          validationSchema={Yup.object({
            firstName: Yup.string().required('First name is required'),
            lastName: Yup.string().required('Last name is required'),
            email: Yup.string()
              .required('Email is required')
              .email('Please enter a valid email'),
            phone: Yup.string()
              .required('Phone is required')
              .matches(/^[0-9]\d{9}$/, 'Please enter a valid phone number'),
            dobMonth: Yup.number()
              .required('Birth month is required')
              .integer('Please provide a valid birth month')
              .min(1, 'Please provide a valid birth month')
              .max(12, 'Please provide a valid birth month'),
            dobDay: Yup.number()
              .required('Birth day is required')
              .integer('Please provide a valid birth day')
              .min(1, 'Please provide a valid birth day')
              .max(32, 'Please provide a valid birth day'),
            dobYear: Yup.number()
              .required('Birth year is required')
              .integer('Please provide a valid birth year')
              .min(1900, 'Please provide a valid birth year')
              .max(moment().year(), 'Please provide a valid birth year'),
            perio: Yup.boolean(),
            address1: Yup.string().when('address2', {
              is: val => val,
              then: Yup.string().required('Please submit a complete address')
            }),
            address2: Yup.string(),
            state: Yup.mixed().oneOf(Object.values(Types.State), 'Please submit a valid state (abbreviation)'),
            zip: Yup.string().matches(/^[0-9]\d{4}$/, 'Please enter a valid zip code (5 digits)')
          })}
        >
          <Form>
            <CardHeader>
              <Heading size={500}>Your Profile</Heading>
            </CardHeader>
            <Pane padding={16} display="flex" flexDirection="column">
              <MemberFields />
            </Pane>
            <Pane display="flex" justifyContent="space-between" alignItems="center" elevation={0} padding={16}>
              <FormError max={2} marginTop={0} />
              <Button
                isLoading={updateProfileStatus.loading || updateIdentityStatus.loading}
                type="submit"
                height={40}
                appearance="primary"
              >
                Save
              </Button>
            </Pane>
          </Form>
        </Formik>
      </Card>
    </Pane>
  )
}

export default styled(AccountView)`
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
  grid-template-areas: 'profile';
  grid-gap: 16px;
`
