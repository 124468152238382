import React from 'react'
import styled from 'styled-components'
import { useQuery } from '@apollo/react-hooks'
import * as Types from '../types'
import { Pane, Heading, Paragraph } from 'evergreen-ui'

import { Card, CardHeader, prettyPhoneNumber } from '@pearly/lib'
import { useAppGlobal } from 'components/app-global-provider'
import { GET_OFFICES } from '../graphql/_office-sheet'
export type Props = {
  className?: string
}

const SupportView = ({ className }: Props) => {
  const { global } = useAppGlobal()
  const contact = global?.meContact.secure
  const { data: dataOffice } = useQuery<Types.FirstOfficeServicePlan>(GET_OFFICES)
  
  
  return (
    <Pane className={className}>
      <Card gridArea="ticket" padding={0} style={{ padding: 0 }}>
        <CardHeader>
          <Heading size={500}>Contact Support</Heading>
        </CardHeader>
        <Pane padding={16}
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center">
          <Paragraph>Email: {dataOffice?.firstOfficeForContact?.email}</Paragraph>
          <Paragraph>Phone: { prettyPhoneNumber(dataOffice?.firstOfficeForContact?.phone)}</Paragraph>
        </Pane>
        {/* <Formik
          initialValues={{ message: '' }}
          onSubmit={({ message }, { resetForm }) => {
            if (!contact) return toaster.danger('Something went wrong - please reach out to support directly')
            const { email, firstName, lastName } = contact

            resetForm()
            postSupportForm({ email, firstName, lastName, message })
          }}
          validationSchema={Yup.object({
            message: Yup.string().required('Summary is required')
          })}
        >
          <Form>
            <Pane padding={16}>
              <Text display="block" marginBottom={16}>
                Please contact TruBlu directly or submit a ticket below and we will respond within 24 hours.
              </Text>
              <Textarea name="message" rows="4" placeholder="Tell us how we can help..." marginBottom={0} />
              <Pane display="flex" justifyContent="space-between" paddingTop={16}>
                <FormError />
                <Button appearance="primary" type="submit">
                  Submit Ticket
                </Button>
              </Pane>
            </Pane>
          </Form>
        </Formik> */}
      </Card>
    </Pane>
  )
}

export default styled(SupportView)`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-template-areas: 'ticket';
  grid-gap: 16px;
`
